<template>
    <div class="wwd-col navbar-container">
        <div class="navbar-wrapper">
            <TabMenu @tab-change="tabChange" class="menu" :model="items_list" />
            <div class="side-navbar">

            <a href="https://support.easygen.org/en-US/guides/simulation-in-the-cloud/0-introduction#0-introduction_cloud-simulation---quick-start-guide" target="_blank"> Help </a>
            <!-- <a href="/privacy" target="_blank"> Privacy Policy </a> -->
            <a href="https://www.woodward.com/en/terms-and-conditions" target="_blank"> Terms & Conditions </a>
            <a href="" @click.prevent="$CookieConsent.show(true)"> Cookies </a>
            <a href="https://easygen.org/contact/" target="_blank"> Give Feedback </a>


            
            </div>
        </div>
        <it-divider style="margin: 2px 0 10px 0;"></it-divider>
        
    </div>
</template>

<script>
    import TabMenu from 'primevue/tabmenu';
    export default {
        components: {
            TabMenu
        },
        data() {
            return {
                advanced_nav_list: [
                    {label: 'Home', icon: 'pi pi-fw pi-home', to: '/home/simulation'},
                    {label: 'Settings', icon: 'pi pi-fw pi-cog', to: '/home/settings'},
                    {label: 'Assets', icon: 'pi pi-fw pi-database', to: '/home/assets'},
                    {label: 'Releases', icon: 'pi pi-fw pi-box', to: '/home/releases'},
                    {label: 'Account', icon: 'pi pi-fw pi-user', to: '/home/account'}
                ],
                nav_list: [
                    {label: 'Home', icon: 'pi pi-fw pi-home', to: '/home/simulation'},
                    {label: 'Settings', icon: 'pi pi-fw pi-cog', to: '/home/settings'},
                    {label: 'Releases', icon: 'pi pi-fw pi-box', to: '/home/releases'},
                    {label: 'Account', icon: 'pi pi-fw pi-user', to: '/home/account'}
                ]
            }
        },
        computed: {
            items_list() {
                return this.$store.getters.userPreferences?.advanced_mode ? this.advanced_nav_list : this.nav_list
            }
        },
        methods: {
            tabChange(ev) {
                if (ev.index === 5) {
                    open('https://support.easygen.org/en-US/guides/simulation-in-the-cloud/0-introduction#0-introduction_cloud-simulation---quick-start-guide', '_blank')
                }
            }
        }
    }
</script>

<style>

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    color: var(--red1) !important;
    border-color: var(--red1) !important;
    /* background-color: #ffffff; */
    /* background-color: var(--darkmode_color1) !important; */
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
}
.p-tabmenu .p-tabmenu-nav .p-menuitem-link {
    color: var(--font1) !important;
    background-color: var(--color2) !important;
    border-radius: unset !important;
    border-color: var(--color3) !important;
}
.p-tabmenu .p-tabmenu-nav {
    color: var(--font1) !important;
    background-color: var(--color2) !important;
    border-radius: unset !important;
    border-color: var(--color3) !important;
    border: unset !important;
}
@media (max-width: 690px) {
    .p-tabmenu-nav .p-menuitem-text {
        display: none;
    }
}
.navbar-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.menu {
    /* width: 100%; */
    /* height: 2.1rem; */
    overflow: hidden;
}
.it-divider {
    background-color: var(--color3) !important;
}
.side-navbar {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    /* justify-content: flex-end; */
    /* padding-top: 0.5rem; */
}
.side-navbar > * {
    color: var(--font1);
    padding: 0.6rem;
    font-weight: 600;
    font-size: 0.9rem;

}

</style>